import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"

const MissionStatementPage = ({ data: { strapiMissionStatement } }) => (
  <Layout bodyTheme="theme-books">
    <SEO title="Mission Statement" />
    <header>
      <Menu />
    </header>
    <main className="page-wrapper">
      <section className="section section-first">
        <nav className="mission-statement-nav">
          <div className="container">
            <ul>
              <li>
                <Link to="/mission-statement" className="active">
                  Overview
                </Link>
              </li>
              <li>
                <Link to="/championing-individuals">
                  Championing Individuals
                </Link>
              </li>
              <li>
                <Link to="/empowering-groups">Empowering Groups</Link>
              </li>
              <li>
                <Link to="/experience">Experience</Link>
              </li>
            </ul>
          </div>
        </nav>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center mb-2">
                <div className="page-heading">
                  <h1>{strapiMissionStatement["Title"]}</h1>
                  <p>{strapiMissionStatement["Description"]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {strapiMissionStatement["Content"].map((section, idx) => {
        return (
          <section className="section-mission-statement">
            <div className="container">
              <div className="mission-statement-grid">
                <div className="mission-statement-grid-content">
                  <div key={idx}>
                    {idx !== 0 ? <hr /> : null}
                    <h2>{section.Title}</h2>
                    <ReactMarkdown source={section["Content"]} />
                  </div>
                </div>
                <div className="mission-statement-grid-media">
                  <div className="mission-statement-grid-media-image">
                    <img
                      alt=""
                      src={
                        section["razaroon"]["razaroonImage"]["localFile"][
                          "publicURL"
                        ]
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      })}

      <Footer />
    </main>
  </Layout>
)

export const query = graphql`
  query MissionStatement {
    strapiMissionStatement {
      id
      Content {
        Content
        Title
        razaroon {
          name
          razaroonImage {
            localFile {
              publicURL
            }
          }
        }
      }
      Description
      Title
    }
  }
`
export default MissionStatementPage
